import { createSlice } from '@reduxjs/toolkit';

import { changePasswordAction } from '../actions/profileActions';
import { NotificationType } from '../enums/Notification';
import { profileMessages } from '../messages/profile';
import type { NotificationObject } from '../types/Notification';
import { buildNotification } from '../utils/notification';

export type ChangePasswordStatus =
  | 'CHANGE_PASSWORD_PENDING'
  | 'CHANGE_PASSWORD_SUCCESSFUL'
  | 'CHANGE_PASSWORD_FAILURE';

export interface ChangePasswordState {
  notification?: NotificationObject;
  status?: ChangePasswordStatus;
}

export const changePasswordSliceInitialState: ChangePasswordState = {};

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: changePasswordSliceInitialState,
  reducers: {
    resetState: (state) => {
      state.notification = undefined;
      state.status = undefined;
    },
    updateNotification: (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.notification = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordAction.fulfilled, (state) => {
        state.status = 'CHANGE_PASSWORD_SUCCESSFUL';
        state.notification = buildNotification(
          profileMessages.success_updating_profile,
          NotificationType.success,
        );
      })
      .addCase(changePasswordAction.rejected, (state, { payload }) => {
        state.status = 'CHANGE_PASSWORD_FAILURE';
        state.notification = buildNotification(
          String(payload),
          NotificationType.error,
        );
      })
      .addCase(changePasswordAction.pending, (state) => {
        state.status = 'CHANGE_PASSWORD_PENDING';
        state.notification = undefined;
      });
  },
});

export const changePasswordActions = changePasswordSlice.actions;

import { createSlice } from '@reduxjs/toolkit';

import {
  changePasswordWithResetTokenAction,
  sendResetPasswordEmailAction,
} from '../actions/forgotPasswordActions';
import { NotificationType } from '../enums/Notification';
import type { NotificationObject } from '../types/Notification';
import { buildNotification } from '../utils/notification';

export enum ForgotPasswordStatus {
  sendResetPasswordEmailSuccessful = 'SEND_RESET_PASSWORD_EMAIL_SUCCESSFUL',
  sendResetPasswordEmailFailure = 'SEND_RESET_PASSWORD_EMAIL_FAILURE',
  changePasswordSuccessful = 'CHANGE_PASSWORD_SUCCESSFUL',
  changePasswordFailure = 'CHANGE_PASSWORD_FAILURE',
}

export interface ForgotPasswordState {
  notification?: NotificationObject;
  status?: ForgotPasswordStatus;
}

export const forgotPasswordSliceInitialState: ForgotPasswordState = {};

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: forgotPasswordSliceInitialState,
  reducers: {
    resetState: (state) => {
      state.notification = undefined;
      state.status = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendResetPasswordEmailAction.fulfilled, (state) => {
        state.status = ForgotPasswordStatus.sendResetPasswordEmailSuccessful;
        state.notification = undefined;
      })
      .addCase(sendResetPasswordEmailAction.rejected, (state, { payload }) => {
        state.status = ForgotPasswordStatus.sendResetPasswordEmailFailure;
        state.notification = buildNotification(
          String(payload),
          NotificationType.error,
        );
      })
      .addCase(sendResetPasswordEmailAction.pending, (state) => {
        state.status = undefined;
        state.notification = undefined;
      })
      .addCase(changePasswordWithResetTokenAction.fulfilled, (state) => {
        state.status = ForgotPasswordStatus.changePasswordSuccessful;
        state.notification = undefined;
      })
      .addCase(
        changePasswordWithResetTokenAction.rejected,
        (state, { payload }) => {
          state.status = ForgotPasswordStatus.changePasswordFailure;
          state.notification = buildNotification(
            String(payload),
            NotificationType.error,
          );
        },
      )
      .addCase(changePasswordWithResetTokenAction.pending, (state) => {
        state.status = undefined;
        state.notification = undefined;
      });
  },
});

export const forgotPasswordActions = forgotPasswordSlice.actions;

import { createSlice } from '@reduxjs/toolkit';

import { changeProfileAction } from '../actions/profileActions';
import { NotificationType } from '../enums/Notification';
import { profileMessages } from '../messages/profile';
import type { NotificationObject } from '../types/Notification';
import { buildNotification } from '../utils/notification';

export type ProfileStatus =
  | 'CUSTOMER_UPDATE_PENDING'
  | 'CUSTOMER_UPDATE_SUCCESSFUL'
  | 'CUSTOMER_UPDATE_FAILURE';

export interface ProfileState {
  notification?: NotificationObject;
  status?: ProfileStatus;
}

export const profileSliceInitialState: ProfileState = {};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: profileSliceInitialState,
  reducers: {
    resetState: (state) => {
      state.notification = undefined;
      state.status = undefined;
    },
    updateNotification: (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.notification = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeProfileAction.fulfilled, (state) => {
        state.status = 'CUSTOMER_UPDATE_SUCCESSFUL';
        state.notification = buildNotification(
          profileMessages.success_updating_profile,
          NotificationType.success,
        );
      })
      .addCase(changeProfileAction.rejected, (state, { payload }) => {
        state.status = 'CUSTOMER_UPDATE_FAILURE';
        state.notification = buildNotification(
          String(payload),
          NotificationType.error,
        );
      })
      .addCase(changeProfileAction.pending, (state) => {
        state.status = 'CUSTOMER_UPDATE_PENDING';
        state.notification = undefined;
      });
  },
});

export const profileActions = profileSlice.actions;

export const customerAccessTokenCreateQuery = (
  email: string,
  password: string,
) => ({
  variables: {
    input: {
      email,
      password,
    },
  },
  mutation: `
        mutation customerAccessTokenCreate(
            $input: CustomerAccessTokenCreateInput!
        ) {
            customerAccessTokenCreate(input: $input) {
                customerAccessToken {
                    accessToken
                    expiresAt
                }
                customerUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `,
});

export const checkoutCreateQuery = () => ({
  variables: {
    input: {},
  },
  mutation: `
        mutation checkoutCreate($input: CheckoutCreateInput!) {
            checkoutCreate(input: $input) {
                checkout {
                    id
                }
                checkoutUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `,
});
